<template>
  <div class="perMap">
    <div class="top-tit">和隆优化业绩地图</div>
    <div class="bot-tit flex">
      <div class="bot-tit1" @click="toCeipage">CEI统计</div>
      <div class="bot-tit2" @click="toOtherpage">通讯总览</div>
    </div>
    <div class="mapContainer flex">
      <div class="mapleft">
        <div class="mapleft-1">
          <div class="mapleft-1-tit">搜索类型</div>
          <div class="back" @click="back">返回</div>
          <div class="mapcheckbox flex align-cen">
            <el-checkbox v-model="all" @change="alltrue">全部</el-checkbox>
          </div>
          <div class="mapleft-2 flex">
            <div class="box">
              <el-select
                v-model="value"
                placeholder="行业"
                clearable
                @change="changeVal"
              >
                <el-option
                  v-for="item in options"
                  :key="item.name"
                  :label="item.label"
                  :value="item.label"
                >
                  <span>{{ item.label }}</span>
                  <span>{{ item.value }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="box">
              <el-select
                v-model="value1"
                placeholder="产品类型"
                clearable
                @change="changeVal1"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.name"
                  :label="item.label"
                  :value="item.label"
                >
                  <span>{{ item.label }}</span>
                  <span>{{ item.value }}</span>
                </el-option>
              </el-select>
            </div>
            <div
              @click="toLink()"
              class="box"
              :class="!disabled ? 'current' : ''"
            >
              项目名称
            </div>
          </div>
          <div class="ipt">
            <el-input
              placeholder="请输入项目名称"
              clearable
              :disabled="disabled"
              v-model="val"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="changeVal2"
              ></el-button>
            </el-input>
          </div>
        </div>
        <div class="mapleft-list">
          <div class="mapleft-list-tit flex align-cen">
            <span class="el-icon-location-outline"></span>
            <div class="text">{{ cityName }}</div>
          </div>
          <div class="row">
            <div
              class="row-product align-cen"
              v-for="item in list"
              :key="item.device_name"
            >
              <div class="flex" @click="openProject(item)">
                <div class="banner">
                  <img :src="item.product_type_name | typeFifter" alt="" />
                </div>
                <div class="prodouct">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="`${item.project_name}#${item.product_type_name}`"
                    placement="top-start"
                  >
                    <div class="tit">
                      {{ item.project_name }}#{{ item.product_type_name }}
                    </div>
                  </el-tooltip>
                  <div class="name">经理{{ item.engineer }}</div>
                  <div class="time">{{ item.project_start_day }}</div>
                </div>
                <div class="ma-auto">
                  <div class="status">{{ item.industry }}</div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.province"
                    placement="top-start"
                  >
                    <div class="co" style="font-size: 0.8vw">
                      {{ item.province }}
                    </div>
                  </el-tooltip>
                </div>
              </div>
              <div class="flex" style="font-size: 0.8vw">
                <div class="button" @click="openVPN(item)">
                  {{ item.vpnHtml }}
                </div>
                <div class="button" @click="closeVPN(item)">
                  {{ item.vpnHtml1 }}
                </div>
                <div class="button" @click="openmstsc(item)">远程连接</div>
                <div class="button" @click="openWeb(item)">WEB访问</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mapright">
        <div class="mapright-tit">企业分布</div>
        <div class="bdMap">
          <div id="mapDiv" class="map-wrap"></div>
          <!-- <baidu-map class="map-wrap" :center="mapData.center" :zoom="mapData.zoom" @ready="mapHandler"
						@click="getLocation">
						<bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
						<bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-city-list>
						<bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
						<bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="false" :autoLocation="true">
						</bm-geolocation>
						<bm-marker v-for="(item,index) of pointList" :key="index" :position="item.markerPoint"
							:dragging="true" :title="item.project_name" animation="BMAP_ANIMATION_DROP"
							:icon="{url: require('../../assets/images/loca.gif'), size: {width: 34, height: 34}}">
							<div class="bmlaber">
								<p></p>
								<p></p>
							</div>
							<bm-label
								:content="'<div class=' + 'bmlaber><p><span>项目名称：</span>' +item.project_name +'</p><p><span>项目类型：</span>' +item.product_type_name +'</p></div>'"
								:title="item.project_name" />
						</bm-marker>
					</baidu-map> -->
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :show-close="false"
      custom-class="anyDeskPop"
    >
      <div class="PopHt">
        <div class="param-header-icon" @click="dialogVisible = false">
          <img
            src="~@/assets/images/close.png"
            style="width: 100%; height: auto"
          />
        </div>
        <div class="PopHt-tit">远程桌面连接</div>
        <div class="dialog-content">
          <el-form ref="form" :model="formData" label-width="7vw">
            <el-form-item style="font-size: 3vh" label="系统IP:">
              <!-- <div style="font-size: 2vh;">{{formData.sysip}}</div> -->
              <el-input
                style="width: 10vw"
                v-model="formData.sysip"
                readonly
              ></el-input>

              <el-button
                style="width: 11vw; font-size: 0.8vw; margin-left: 1vw"
                type="primary"
                @click="onCopyIP"
                >复制IP</el-button
              >
            </el-form-item>

            <br />
            <!-- <el-form-item style="font-size: 3vh;" label="IP地址:">
							<div style="font-size: 2vh;">{{formData.sysip}}</div>
						</el-form-item>
						<br> -->
            <el-form-item style="font-size: 3vh" label="密码:">
              <!-- <div style="font-size: 2vh;">{{formData.syspassword}}</div> -->
              <el-input
                style="width: 10vw"
                v-model="formData.syspassword"
                type="password"
                readonly
              ></el-input>

              <el-button
                style="width: 11vw; font-size: 0.8vw; margin-left: 1vw"
                type="primary"
                @click="onCopy"
                >&nbsp;&nbsp;复制密码&nbsp;&nbsp;</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="PopHt-note">注：远程软件为anydesk</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!-- <el-button type="primary" @click="dialogVisible = false">复制密码</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
var map;
var zoom;
var geocoder;
import { getSpread } from "@/api/index/index";
import { mapSearch } from "@/api/index/index";
import { authProjectlist, vpnLink } from "@/api/otherview/index"; //用户有权查看的列表
export default {
  name: "cityMap",
  components: {},
  data: () => {
    return {
      disabled: true,
      value: "",
      value1: "",
      show: true,
      options: [
        {
          name: "metallurgy",
          label: "冶金",
        },
        {
          name: "industry",
          label: "化工",
        },
        {
          name: "heat",
          label: "热电",
        },
      ],
      options1: [
        {
          name: "rfl",
          label: "热风炉",
        },
        {
          name: "jrl",
          label: "加热炉",
        },
        {
          name: "jrlse",
          label: "加热炉二级",
        },
        {
          name: "rql",
          label: "燃气炉",
        },
        {
          name: "sjj",
          label: "烧结机",
        },
        {
          name: "hzy",
          label: "石灰窑(回转窑)",
        },
        {
          name: "sls",
          label: "石灰窑(双梁式)",
        },
        {
          name: "slyh",
          label: "竖炉优化",
        },
        {
          name: "cfb",
          label: "CFB",
        },
        {
          name: "mfl",
          label: "煤粉炉",
        },
        {
          name: "hbd",
          label: "环保岛",
        },
        {
          name: "rszz",
          label: "燃烧装置",
        },
        {
          name: "zqgw",
          label: "蒸汽管网",
        },
        {
          name: "dcc",
          label: "电除尘",
        },
        {
          name: "hgzz",
          label: "化工装置",
        },
        {
          name: "jlt",
          label: "精馏塔",
        },
        {
          name: "fyf",
          label: "反应釜",
        },
        {
          name: "pvcx",
          label: "PVC旋风干燥",
        },
        {
          name: "pvcl",
          label: "PVC流化床干燥",
        },
        {
          name: "snscx",
          label: "水泥生产线",
        },
        {
          name: "snm",
          label: "水泥磨",
        },
        {
          name: "lhs",
          label: "硫回收",
        },
        {
          name: "hca",
          label: "合成氨",
        },
        {
          name: "bsl",
          label: "焙烧炉",
        },
        {
          name: "jhf",
          label: "聚合釜",
        },
        {
          name: "train",
          label: "专业培训",
        },
      ],
      cityData: [],
      cityName: "",
      list: [],
      pointList: [],
      all: true,
      flag: 0,
      placeholder: "请输入查询内容",
      val: "",
      //地址信息(包含坐标信息)
      businessDetail: {
        address: "",
        province: "",
        city: "",
        longitude: "",
        latitude: "",
      },
      //地图数据
      mapData: {
        //中心坐标
        center: {
          lng: 0,
          lat: 0,
        },
        //缩放级别
        zoom: 13,
      },
      //BMap类
      BMap: null,
      dialogVisible: false,
      formData: {
        sysuser: "",
        sysip: "",
        syspassword: "",
      },
    };
  },
  created() {
    this.getMapserch();
    this.getSpread();
    this.cityName = this.$route.query.cityName;
    if (this.$route.query.cityData)
      this.cityData = JSON.parse(this.$route.query.cityData);
    if (this.$route.query.cityData)
      this.businessDetail.longitude = this.cityData[0];
    if (this.$route.query.cityData)
      this.businessDetail.latitude = this.cityData[1];
    zoom = this.cityData[2] ? 14 : 8;
  },
  mounted() {},
  methods: {
    openVPN(row) {
      localStorage.setItem(
        "deviceType",
        JSON.stringify({
          type: row.product_type,
          device: row.device_name,
        })
      );
      let authinfo = JSON.parse(localStorage.getItem("authInfo"));
      localStorage.removeItem("spotArr");
      let result = authinfo.filter(
        (item) => item.id === row.device_name + "_r"
      );
      if (result.length == "1") {
        console.log(row);
        if (row.vpnHtml == "VPN开启") {
          let params = {
            device_name: row.device_name,
            active: 1,
          };
          console.log(params);
          vpnLink(params)
            .then((res) => {
              if ((res.device_name = row.device_name)) {
                this.$message.success("VPN已打开");
              }
            })
            .catch((err) => {
              this.$message.error("未知错误，请联系管理员");
            });
          row.vpnHtml = "已开启";
        } else {
          this.$message.error("请断开VPN后再开启。");
        }
      } else {
        this.$message.success("您没有VPN连接权限");
      }
      // console.log(row)
    },
    closeVPN(row) {
      localStorage.setItem(
        "deviceType",
        JSON.stringify({
          type: row.product_type,
          device: row.device_name,
        })
      );
      let authinfo = JSON.parse(localStorage.getItem("authInfo"));
      localStorage.removeItem("spotArr");
      let result = authinfo.filter(
        (item) => item.id === row.device_name + "_r"
      );
      if (result.length == "1") {
        if (row.vpnHtml == "已开启") {
          let params = {
            device_name: row.device_name,
            active: 0,
          };
          // console.log(params, 'VPN断开这边')
          vpnLink(params)
            .then((res) => {
              if ((res.device_name = row.device_name)) {
                this.$message.success("VPN已断开");
              }
            })
            .catch((err) => {
              this.$message.error("未知错误，请联系管理员");
            });
          row.vpnHtml = "VPN开启";
        } else {
          this.$message.error("请开启VPN后再进行断开操作。");
        }
      } else {
        this.$message.success("您没有VPN连接权限");
      }
    },
    openWeb(row) {
      localStorage.setItem(
        "deviceType",
        JSON.stringify({
          type: row.product_type,
          device: row.device_name,
        })
      );
      let authinfo = JSON.parse(localStorage.getItem("authInfo"));
      localStorage.removeItem("spotArr");
      let result = authinfo.filter(
        (item) => item.id === row.device_name + "_r"
      );
      if (result.length == "1") {
        let webUrl = row.url;
        console.log(webUrl, "++++++++");
        if (!!webUrl) {
          window.open(webUrl);
        } else {
          this.$message.error("该项目暂无此功能");
        }
      } else {
        this.$message.success("您没有WEB访问的权限");
      }
    },
    // 打开远程桌面
    openmstsc(row) {
      localStorage.setItem(
        "deviceType",
        JSON.stringify({
          type: row.product_type,
          device: row.device_name,
        })
      );
      let authinfo = JSON.parse(localStorage.getItem("authInfo"));
      localStorage.removeItem("spotArr");
      let result = authinfo.filter(
        (item) => item.id === row.device_name + "_r"
      );
      if (result.length == "1") {
        this.dialogVisible = true;
        if (row.sysip) {
          this.formData.sysip = row.sysip;
        } else {
          this.formData.sysip = "暂未录入";
        }
        if (row.syspassword) {
          this.formData.syspassword = row.syspassword;
        } else {
          this.formData.syspassword = "暂未录入";
        }
        console.log(this.formData.sysip, "运行到此");
      } else {
        this.$message.success("您没有操作远程桌面的权限");
      }
    },
    onCopyIP() {
      //创建input标签
      var input = document.createElement("input");
      //将input的值设置为需要复制的内容
      input.value = this.formData.sysip;
      // console.log(this.formData.syspassword);
      if (this.formData.sysip == null) {
        this.$message.error("系统IP为空，请联系管理员！");
      } else {
        //添加input标签
        document.body.appendChild(input);
        //选中input标签
        input.select();
        //执行复制
        document.execCommand("copy");
        //成功提示信息
        this.$message.success("IP复制成功！");
        //移除input标签
        document.body.removeChild(input);
        // this.copyFunc(this.formData.syspassword)
        // this.dialogVisible = false;
        // var command = "mstsc /v:192.168.1.171 /admin /f" //这里是执行的DOS命令
        // window.open("TestCallEXE://")
      }
    },
    onCopy() {
      //创建input标签
      var input = document.createElement("input");
      //将input的值设置为需要复制的内容
      input.value = this.formData.syspassword;
      // console.log(this.formData.syspassword);
      if (this.formData.syspassword == null) {
        this.$message.error("密码为空，请联系管理员！");
      } else {
        //添加input标签
        document.body.appendChild(input);
        //选中input标签
        input.select();
        //执行复制
        document.execCommand("copy");
        //成功提示信息
        this.$message.success("密码复制成功！");
        //移除input标签
        document.body.removeChild(input);
        // this.copyFunc(this.formData.syspassword)
        // this.dialogVisible = false;
        // var command = "mstsc /v:192.168.1.171 /admin /f" //这里是执行的DOS命令
        // window.open("TestCallEXE://")
      }
    },
    // 直接打开项目
    openProject(row) {
      localStorage.setItem(
        "deviceType",
        JSON.stringify({
          type: row.product_type,
          device: row.device_name,
        })
      );
      let authinfo = JSON.parse(localStorage.getItem("authInfo"));
      localStorage.removeItem("spotArr");
      let result = authinfo.filter(
        (item) => item.id === row.device_name + "_r"
      );
      if (result.length == "1") {
        let autharr = [];
        for (let i in authinfo) {
          if (authinfo[i].pid === row.device_name) {
            autharr.push(authinfo[i].id);
          }
        }
        localStorage.setItem("autharr", JSON.stringify(autharr));
        switch (row.product_type) {
          case "rfl":
            return this.$router.push({
              path: "/index",
            });
          case "jrl":
            return this.$router.push({
              path: "/Jrindex",
            });
          case "rql":
            return this.$router.push({
              path: "/Rqindex",
            });
          case "cfb":
            return this.$router.push({
              path: "/Cfbindex",
            });
          case "mfl":
            return this.$router.push({
              path: "/Mfindex",
            });
        }
      } else {
        this.$message.success("您没有WEB访问的权限");
      }
    },
    back() {
      this.$router.push({
        path: "/mapIndex",
      });
    },
    //  下拉框选中的值
    changeVal(e) {
      this.value = e;
      this.all = false;
      this.getMapserch();
    },
    changeVal1(e) {
      this.value1 = e;
      this.all = false;
      this.getMapserch();
    },
    changeVal2() {
      this.getMapserch();
    },
    //    获取点的经纬度
    getSpread() {
      getSpread({})
        .then((res) => {
          let newSpread = res.spread;
          for (let i in newSpread) {
            this.pointList.push({
              name: newSpread[i].name,
              markerPoint: {
                lng: newSpread[i].value[0],
                lat: newSpread[i].value[1],
              },
              project_name: newSpread[i].value[2],
              product_type_name: newSpread[i].value[3],
            });
          }
          this.loadMap();
        })
        .catch((err) => {});
    },
    // 项目列表
    getMapserch() {
      let parmsdata;
      if (this.value && !this.value1 && !this.val) {
        parmsdata = {
          industry: this.value,
          province: this.cityName,
        };
      } else if (this.value && this.value1 && !this.val) {
        parmsdata = {
          industry: this.value,
          product_type_name: this.value1,
          province: this.cityName,
        };
      } else if (this.value && !this.value1 && this.val) {
        parmsdata = {
          industry: this.value,
          project_name: this.val,
          province: this.cityName,
        };
      } else if (this.value1 && !this.value && !this.val) {
        parmsdata = {
          product_type_name: this.value1,
          province: this.cityName,
        };
      } else if (this.value1 && !this.value && this.val) {
        parmsdata = {
          product_type_name: this.value1,
          project_name: this.val,
          province: this.cityName,
        };
      } else if (!this.value1 && !this.value && this.val) {
        parmsdata = {
          project_name: this.val,
          province: this.cityName,
        };
      } else if (this.value1 && this.value && this.val) {
        parmsdata = {
          industry: this.value,
          product_type_name: this.value1,
          project_name: this.val,
          province: this.cityName,
        };
      } else {
        parmsdata = {
          province: this.$route.query.cityName,
        };
      }
      // console.log(parmsdata,"列表信息")
      mapSearch(parmsdata)
        .then((res) => {
          this.list = res;
          console.log(res, "列表信息");
          if (res.vpnHtml) {
            console.log("有定义");
          } else {
            for (let k in this.list) {
              this.$set(this.list[k], "vpnHtml", "VPN开启");
              this.$set(this.list[k], "vpnHtml1", "VPN关闭");
              // this.tableData[k].vpnHtml = '打开VPN'
            }
          }
          // console.log(res,"这里")
        })
        .catch((err) => {
          this.$message({
            message: "获取失败",
            type: "error",
          });
        });
    },
    loadMap() {
      let that = this;
      //初始化地图对象
      map = new T.Map("mapDiv");
      //设置显示地图的中心点和级别
      let lng = "116.25435";
      let lat = "40.08225";
      if (this.businessDetail.longitude) {
        // 坐标存在
        lng = this.businessDetail.longitude;
        lat = this.businessDetail.latitude;
      } else {
        this.businessDetail.address = "北京市海淀区北清路81号中关村壹号B1座801";
        this.businessDetail.province = "北京";
        this.businessDetail.city = "北京";
        this.businessDetail.longitude = lng;
        this.businessDetail.latitude = lat;
      }
      map.centerAndZoom(new T.LngLat(lng, lat), zoom);
      //创建缩放平移控件对象
      let control = new T.Control.Zoom();
      map.addControl(control);
      var miniMap = new T.Control.OverviewMap({
        isOpen: true,
        size: new T.Point(150, 150),
      });
      map.addControl(miniMap);
      var icon = new T.Icon({
        iconUrl: "http://api.tianditu.gov.cn/img/map/markerA.png",
        iconSize: new T.Point(19, 27),
        iconAnchor: new T.Point(10, 25),
      });
      let marker = new T.Marker(new T.LngLat(lng, lat), { icon: icon });
      map.addOverLay(marker);
      console.log(this.$route.query);
      var lnglat = new T.LngLat(lng, lat);
      //创建信息窗口对象
      var infoWin = new T.InfoWindow();
      infoWin.setLngLat(lnglat);
      infoWin.setOffset(new T.Point(0, -20));
      let con = this.cityData[2]
        ? `${this.cityData[2]}#${this.cityData[3]}`
        : this.$route.query.cityName;
      //设置信息窗口要显示的内容
      infoWin.setContent(con);
      //向地图上添加信息窗口
      map.addOverLay(infoWin);
      for (var i = 0; i < this.pointList.length; i++) {
        let tit = `${this.pointList[i].project_name}#${this.pointList[i].product_type_name}`;
        marker = new T.Marker(
          new T.LngLat(
            this.pointList[i].markerPoint.lng,
            this.pointList[i].markerPoint.lat
          ),
          { icon: icon }
        );
        map.addOverLay(marker);
        this.addClickHandler(tit, marker);
        lnglat = new T.LngLat(
          this.pointList[i].markerPoint.lng,
          this.pointList[i].markerPoint.lat
        );
        var infoWin = new T.InfoWindow();
        infoWin.setLngLat(lnglat);
        infoWin.setOffset(new T.Point(0, -20));
        //设置信息窗口要显示的内容
        infoWin.setContent(tit);
        //向地图上添加信息窗口
        map.addOverLay(infoWin);
      }
    },
    addClickHandler(content, marker) {
      marker.addEventListener("click", function (e) {
        console.log(e);
        // openInfo(content,e)
      });
    },
    //地图预处理
    async mapHandler({ BMap, map }) {
      if (this.businessId) {
        //可以在此处请求接口获取坐标数据
        await this.getMallBusinessDetail();
      }
      //保存百度地图类
      this.BMap = BMap;
      //保存地图对象
      this.map = map;
      //如果一开始坐标存在(编辑的时候)
      if (this.businessDetail.longitude && this.businessDetail.latitude) {
        //设置坐标
        this.mapData.center.lng = this.businessDetail.longitude;
        this.mapData.center.lat = this.businessDetail.latitude;
      } else {
        //如果坐标不存在则动态获取当前浏览器坐标（创建的时候）
        let geolocation = new BMap.Geolocation();
        //获取当前的坐标（使用promise 将异步转换为同步）
        await new Promise((resolve) => {
          geolocation.getCurrentPosition((r) => {
            this.businessDetail.address = r.address.province + r.address.city;
            this.businessDetail.province = r.address.province;
            this.businessDetail.city = r.address.city;
            this.mapData.center.lng = this.businessDetail.longitude =
              r.point.lng;
            this.mapData.center.lat = this.businessDetail.latitude =
              r.point.lat;
            resolve();
          });
        });
      }
      //创建定位标记
      let marker = new BMap.Marker(
        new BMap.Point(
          this.businessDetail.longitude,
          this.businessDetail.latitude
        )
      );
      //将标记添加到地图上
      //   map.addOverlay(marker)
    },
    //在地图上选择区域
    getLocation(e) {
      //设置经度
      this.businessDetail.longitude = e.point.lng;
      //设置纬度
      this.businessDetail.latitude = e.point.lat;
      //百度地图类
      let BMapGL = this.BMap;
      //地图对象
      let map = this.map;
      //清除地图上所有的覆盖物(保证每次点击只有一个标记)
      //   map.clearOverlays()
      //创建定位标记
      let marker = new BMapGL.Marker(
        new BMapGL.Point(e.point.lng, e.point.lat)
      );
      //将标记添加到地图上
      //   map.addOverlay(marker)
      //创建坐标解析对象
      let geoc = new BMapGL.Geocoder();
      //解析当前的坐标成地址
      geoc.getLocation(e.point, (rs) => {
        //获取地址对象
        let addressComp = rs.addressComponents;
        //拼接出详细地址
        this.businessDetail.address =
          addressComp.province +
          addressComp.city +
          addressComp.district +
          addressComp.street +
          addressComp.streetNumber;
        this.businessDetail.province = addressComp.province;
        this.businessDetail.city = addressComp.city;
      });
    },
    alltrue(e) {
      this.value = "";
      this.value1 = "";
      this.val = "";
      this.getMapserch();
      //  console.log(e)
    },
    //  筛选条件
    toLink() {
      this.disabled = !this.disabled;
      this.all = false;
    },
    // 打开通讯总览
    toOtherpage() {
      this.$router.push({
        path: "/userotherPage",
      });
    },
    // 打开CEI页面
    toCeipage() {
      this.$router.push({
        path: "/CeiPage",
      });
    },
  },
  filters: {
    // 图片过滤器
    typeFifter: (val) => {
      switch (val) {
        case "热风炉":
          return require("../../assets/images/serve1.png");
        case "加热炉":
          return require("../../assets/images/2.png");
        case "燃气炉":
          return require("../../assets/images/4.png");
        case "CFB":
          return require("../../assets/images/5.png");
        case "煤粉炉":
          return require("../../assets/images/6.png");
        case "电除尘":
          return require("../../assets/images/3.png");
        case "加热炉二级":
          return require("../../assets/images/7.png");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}

.perMap {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/images/map/map.jpg") no-repeat 0 0;
  background-size: cover;
  overflow: hidden;

  .top-tit {
    width: 100%;
    font-size: 2vw;
    color: #ffffff;
    font-family: "MicrosoftYaHei";
    font-weight: normal;
    font-stretch: normal;
    text-align: center;
    position: absolute;
    // left: 50%;
    // margin-left: -9vw;
    top: 2vh;
  }

  .bot-tit {
    font-size: 1vw;
    color: #02eeff;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    bottom: 4.5vh;
    cursor: pointer;
    z-index: 99;

    .bot-tit1 {
      margin-left: 42.5vw;
    }

    .bot-tit2 {
      margin-left: 8vw;
    }
  }

  .mapContainer {
    width: 97vw;
    height: 91vh;
    margin: 0 auto;
    background: url("~@/assets/images/map/mapborder.png") no-repeat 0 0;
    background-size: 100% 100%;
    margin-top: 4.5vh;
    position: relative;
    padding: 0.1px;

    .mapleft {
      width: 20vw;
      padding-left: 2vw;
      position: relative;

      .back {
        color: #2d95bb;
        position: absolute;
        right: 0;
        top: 4.5vh;
        cursor: pointer;
      }

      .mapleft-1 {
        width: 20vw;
        height: 20vh;
        padding-top: 4.5vh;

        .mapleft-1-tit {
          font-size: 1vw;
          color: #6cf3ff;
          padding-left: 1vw;
          height: 3vh;
          line-height: 3vh;
          background: url("~@/assets/images/map/map1.png") no-repeat 0 0;
          background-size: 100% 100%;
        }

        .mapcheckbox {
          justify-content: end;
        }
      }

      .mapleft-2 {
        width: 100%;
        justify-content: space-between;
        margin: 1vh 0;

        .box {
          padding: 0px 0.5vw;
          border: solid 1px #185378;
          color: #0a6283;
          font-size: 0.8vw;
          font-family: MicrosoftYaHei;
          cursor: pointer;
        }

        .current {
          color: #6cf3ff;
        }
      }

      .ipt {
        margin-top: 3vh;
      }

      .mapleft-list {
        width: 20vw;
        height: calc(100% - 29vh);

        .mapleft-list-tit {
          border-bottom: 1px dashed #185378;
          height: 4vh;

          span {
            color: #fffa6a;
            font-size: 1.2vw;
          }

          .text {
            color: #00f0ff;
            font-size: 1vw;
            font-family: PingFang-SC-Regular;
            padding-left: 0.6vw;
          }
        }

        .row {
          height: calc(100% - 4vh);
          overflow: hidden;
          overflow-y: auto;

          .row-product {
            margin-top: 2vh;
            cursor: pointer;

            .banner {
              height: 8vh;
              width: 6vw;
              background-color: #011f38;
            }

            .prodouct {
              padding-left: 0.5vw;

              .tit {
                font-size: 1vw;
                color: #6cf3ff;
                width: 9vw;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .name {
                color: #1588a9;
                font-size: 0.8vw;
              }

              .time {
                color: #0a6283;
                font-size: 0.7vw;
              }
            }

            .ma-auto {
              padding-right: 0.5vw;

              .status {
                width: 3vw;
                height: 2vh;
                text-align: center;
                line-height: 2vh;
                background-color: #33c412;
                color: #fff;
                font-size: 0.8vw;
                border-radius: 2px;
              }

              .co {
                width: 3vw;
                height: 2vh;
                text-align: center;
                line-height: 2vh;
                font-size: 1vw;
                border: solid 1px #053452;
                border-radius: 2px;
                margin-top: 3vh;
                color: #00d8ff;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .button {
              width: 4vw;
              height: 3vh;
              text-align: center;
              line-height: 3vh;
              color: #6acafb;
              margin-left: 0.7vw;
              margin-top: 0.3vh;
              border: #168db2 1px solid;
            }
          }
        }
      }
    }

    .mapright {
      width: 71vw;
      height: 85vh;
      position: relative;
      margin-left: 2vw;

      .mapright-tit {
        width: 100%;
        height: 4vh;
        background-color: #00357e80;
        position: absolute;
        top: 4.5vh;
        font-size: 1vw;
        line-height: 4vh;
        color: #00eaff;
        text-indent: 2vw;
        z-index: 0;
      }

      .bdMap {
        width: 100%;
        height: calc(100% - 4.5vh);

        .map-wrap {
          width: 100%;
          height: calc(100% - 4.5vh);
          margin: 0 auto;
          border-radius: 5px;
          position: absolute;
          top: 4.5vh;
        }
      }
    }
  }
}

::v-deep {
  .BMapLabel {
    border-radius: 10px;
    border: none !important;
    top: -100px !important;
    left: -15px !important;
    background: #00243b !important;
    //   background: url('~@/assets/images/jrl-param.png') no-repeat 0 0;
    //  background-size: 100%;
  }

  .BMapLabel:after {
    position: absolute;
    top: 100%; //自适应内容框高度定位
    left: 20px;
    width: 0px; //小尾巴的内容宽度，大小决定是否出现漏斗
    height: 0px; //小尾巴的内容宽度，大小决定是否出现漏斗
    content: "";
    box-sizing: border-box;
    border-top: 10px solid #00243b;
    border-left: 10px solid #f3f5f6;
    border-right: 10px solid #f3f5f6;
    background: transparent; //继承内容框背景色
    background-position: -20px bottom;
    background-origin: border-box;
  }

  .bmlaber {
    font-size: 16px;
    color: #00fff6;
    text-align: center;
    padding: 20px 20px;
    width: 100%;
    text-align: center;

    p {
      color: #00fff6;
      text-align: left;
    }

    span {
      color: #00fff6;
      text-align: right;
      display: inline-block;
    }
  }

  .box {
    .el-select {
      width: 6vw;
      height: 100%;

      .el-input__inner {
        background: transparent;
        border: none;
        height: 100%;
        color: #00f0ff;
      }

      .el-input__icon {
        line-height: 27px;
        color: #00f0ff;
      }

      input::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #00f0ff;
        font-size: 0.8vw;
      }
    }
  }

  .mapcheckbox {
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
      border: 1px solid #185378;
      background-color: transparent;
    }

    .el-checkbox__label {
      color: #2d95bb;
      padding-left: 5px;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner::after {
      border: 1px solid #00f8ff;
      border-left: 0;
      border-top: 0;
    }
  }

  .ipt {
    .el-input__inner,
    .el-input-group__append {
      background-color: #00243b;
      border: solid 1px #185378;
      height: 3vh;
      color: #47cfe8;
      font-size: 0.8vw;
    }

    .el-input__inner {
      border-right: 0;
    }

    .el-input-group__append {
      border: solid 1px #185378;
      border-left: 0;
      box-sizing: border-box;
    }

    input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #47cfe8;
      font-size: 12px;
    }

    .el-input__icon {
      line-height: 3vh;
      color: #117897;
    }
  }

  .anyDeskPop {
    width: 35vw !important;
    // height: 40vh!important;
    background-image: url("~@/assets/images/rfl_switch.png");
    background-size: 100% 100%;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // margin: -20vh 0 0 -15vw;
    // margin-top: -20vh!important;

    .PopHt {
      color: #fff;

      .param-header-icon {
        width: 2vw;
        height: 2vh;
        position: absolute;
        right: 1vw;
        top: 1vh;
        cursor: pointer;
      }

      .PopHt-tit {
        width: 33vw;
        text-align: center;
        line-height: 2.7vh;
        font-size: 1.7vh;
        position: absolute;
        top: 0.6vh;
      }

      .PopHt-note {
        width: 33vw;
        // text-align: center;
        line-height: 1.7vh;
        font-size: 1.5vh;
        margin-left: 4vw;
        position: absolute;
        // top: 0.6vh;
      }

      .PopHt-con {
        height: 25vh;

        div {
          div {
            padding-bottom: 2vh;
          }
        }
      }

      .dialog-content {
        margin-top: 5vh;
      }
    }

    .el-form-item {
      display: inline-block !important;
    }

    .el-form-item__label {
      width: 150px;
      color: #168db2;
    }

    .el-input {
      width: 13vw;
      height: 30px;
    }

    .el-input__inner {
      background-color: #0c202f;
      height: 30px;
      border: 0.1px solid #168db2;
      color: #fff;
    }
  }
}
</style>
